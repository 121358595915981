import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

export const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#e1483d',
      },
    },
    props: {
      MuiContainer: {
        maxWidth: 'md',
      },
    },
  }),
);
