/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { Box, Container, CssBaseline, ThemeProvider } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Header } from './Header';
import { theme } from './Theme';

const GlobalStyles = createGlobalStyle`
  html, body, #___gatsby, #___gatsby > div {
    height: 100%;
  }
`;

export const Layout: FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>

      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Header siteTitle={data.site.siteMetadata.title}/>

        <main>{children}</main>

        <Container component="footer">
          <Box
            height={64}
            display="flex"
            alignItems="center"
          >
            © {new Date().getFullYear()}, Mackenzie Etherington
          </Box>
        </Container>
      </Box>
      <GlobalStyles/>
    </ThemeProvider>
  );
};
