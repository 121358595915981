import { Box, Container, Typography } from '@material-ui/core';
import React, { FC } from 'react';

type HeaderProps = {
  siteTitle?: string;
}

export const Header: FC<HeaderProps> = ({ siteTitle = '' }) => (
  <Container component="header">
    <Box marginY={2}>
      <Typography variant="h6">
        <strong>{siteTitle}</strong>
      </Typography>
    </Box>
  </Container>
);
